import { Typography } from "antd";
import React from "react";

const AddRole = () => {
  let { Title } = Typography;
  return (
    <div>
      <div className="m12r">
        <Title level={3}>All Roles</Title>
      </div>
      <div>hello</div>
    </div>
  );
};

export default AddRole;
